import React, { createContext, useState, useEffect, useContext } from 'react';
import posthog from 'posthog-js';

// Create the context
const FeatureFlagContext = createContext();

// Provider component
export const FeatureFlagProvider = ({ children }) => {
    const [featureFlags, setFeatureFlags] = useState({});

    useEffect(() => {
        const userConsent = localStorage.getItem('userConsent');

        if (userConsent && userConsent === 'true') {
            posthog.init('phc_iq0zj4ZGeJSSGkLof8V8DnPqlYiIdQfFgDmjve7wXTJ', {
                api_host: 'https://app.posthog.com',
                autocapture: false // start without autocapture to wait for user consent
            });

            posthog.onFeatureFlags(() => {
                const homepageConversion = posthog.getFeatureFlag('homepage-conversion');
                // console.log("homepageConversion: ", homepageConversion);
                setFeatureFlags({ homepageConversion });
            });
        }

    }, []);

    return (
        <FeatureFlagContext.Provider value={featureFlags}>
            {children}
        </FeatureFlagContext.Provider>
    );
};

// Custom hook for accessing the context
export const useFeatureFlags = () => useContext(FeatureFlagContext);
