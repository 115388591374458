import React from 'react';
import jfk from '../images/speakers/jfk.png';
import stevejobs from '../images/speakers/steve-jobs.png';
import tonysoprano from '../images/speakers/tony-soprano.png';
import shashitharoor from '../images/speakers/shashi-tharoor.png';
import mehdihasan from '../images/speakers/mehdi-hasan.png';
import oprah from '../images/speakers/oprah.png';
import ronaldreagan from '../images/speakers/ronald-reagan.png';
import donaldtrump from '../images/speakers/donald-trump.png';
import eleanorroosevelt from '../images/speakers/eleanor-roosevelt.png';
import tonyrobbins from '../images/speakers/tony-robbins.png';
import mlk from '../images/speakers/mlk.png';
import obama from '../images/speakers/obama.png';
import ted from '../images/speakers/ted.png';
import gartner from '../images/speakers/gartner.png';
import indistinctstyle from '../images/speakers/teleprompter.png'
import nospeakersnotes from '../images/speakers/no-speakers-notes.png'

const jsonData = [
  {
    "descriptors": "",
    "id": 0,
    "priority": 0,
    "template": "No Speakers Notes",
    "description": "No speakers notes will be generated. You will create your own talk track.",
    "thumbnail": nospeakersnotes,
    "price": "$0"
  },
  {
    "descriptors": "",
    "id": 1,
    "priority": 1,
    "template": "John F. Kennedy",
    "description": "35th President of the United States, known for his charismatic speeches.",
    "thumbnail": jfk,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 2,
    "priority": 2,
    "template": "Barack Obama",
    "description": "44th President of the United States, known for his eloquent and inspiring speeches.",
    "thumbnail": obama,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 3,
    "priority": 3,
    "template": "Martin Luther King, Jr.",
    "description": "Civil rights leader known for moving speeches, the 'I Have a Dream' speech amongst his most iconic.",
    "thumbnail": mlk,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 4,
    "priority": 4,
    "template": "Steve Jobs",
    "description": "Co-founder of Apple Inc., known for his captivating product launch presentations.",
    "thumbnail": stevejobs,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 5,
    "priority": 5,
    "template": "Shashi Tharoor",
    "description": "Indian politician, diplomat, and writer known for his articulate English speeches.",
    "thumbnail": shashitharoor,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 6,
    "priority": 6,
    "template": "Ronald Reagan",
    "description": "40th President of the United States, known as 'The Great Communicator.'",
    "thumbnail": ronaldreagan,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 7,
    "priority": 7,
    "template": "Tony Robbins",
    "description": "Motivational speaker and self-help author known for his energetic presentations.",
    "thumbnail": tonyrobbins,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 8,
    "priority": 8,
    "template": "Oprah Winfrey",
    "description": "Talk show host and media mogul, known for her inspiring and empathetic style.",
    "thumbnail": oprah,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 9,
    "priority": 9,
    "template": "TED Talk",
    "description": "A style based on the popular conference series known for spreading ideas.",
    "thumbnail": ted,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 10,
    "priority": 10,
    "template": "Gartner",
    "description": "A leading research and advisory company known for insightful reports.",
    "thumbnail": gartner,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 11,
    "priority": 11,
    "template": "Donald J. Trump",
    "description": "45th President of the United States, known for his direct and unfiltered speaking style.",
    "thumbnail": donaldtrump,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 12,
    "priority": 12,
    "template": "Mehdi Hasan",
    "description": "British political journalist known for his aggressive interviewing style.",
    "thumbnail": mehdihasan,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 13,
    "priority": 13,
    "template": "Tony Soprano",
    "description": "Fictional character from 'The Sopranos' TV series, known for his mob boss demeanor.",
    "thumbnail": tonysoprano,
    "price": "$19"
  },
  {
    "descriptors": "",
    "id": 14,
    "priority": 14,
    "template": "Eleanor Roosevelt",
    "description": "Former First Lady of the United States, known for her advocacy on civil and human rights.",
    "thumbnail": eleanorroosevelt,
    "price": "$19"
  },
  {
    "descriptors": "Speaker's notes with no particular style",
    "id": 15,
    "priority": 15,
    "template": "Indistinct",
    "description": "A generic style without a specific theme or inspiration.",
    "thumbnail": indistinctstyle,
    "price": "$19"
  },
];

export default jsonData;
