import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'; // Import useSearchParams

import SignupModal from './components/SignupModal';
import Error from './Error';
import Logo from './Logo.js'
import makeApiRequest from './util/diffute';  // Importing makeApiRequest
import ApiError from './errors/pptx';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlagEnabled, PostHogFeature, usePostHog, useFeatureFlagVariantKey } from 'posthog-js/react';
import { setMetaTag, setLinkTag } from './util/meta.js';

export default function Signup() {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [hp, setHp] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [isSubmitting, setIsSubmitting] = useState(false);
  const posthog = usePostHog();

  useEffect(() => {
    document.title = "Sign up to Create PowerPoint Presentations with AI";
    const descriptionMeta = document.querySelector('meta[name="description"]');
    if (descriptionMeta) {
      descriptionMeta.setAttribute('content', 'Sign up to Create PowerPoint Presentations with AI');
    }

    const referralParams = ['ref', 'utm_source', 'referrer', 'source'];
    const referralValue = referralParams.reduce((acc, param) => {
      return acc || searchParams.get(param);
    }, '');

    if (referralValue) {
      setFormData((prevState) => ({
        ...prevState,
        ref: referralValue,
      }));
    }

    const isFriendOfRaj = searchParams.get('friend');

  }, [searchParams]); // Add searchParams as a dependency


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const [formData, setFormData] = useState({
    email_address: '',
    full_name: '',
    password: '',
    password2: '',
    hp: '', // Add 'hp' field to the initial state
    ref: '',
    friend: false
  });

  const validateFormData = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate Full Name
    if (!formData.full_name.trim()) {
      setError('Full Name is required.');
      // console.log('Validation failed: Full Name is required');
      isValid = false;
    }

    // Validate Email Address
    else if (!formData.email_address.trim()) {
      setError('Email address is required.');
      console.log('Validation failed: Email address is required');
      isValid = false;
    } else if (!emailRegex.test(formData.email_address.trim())) {
      setError('Please enter a valid email address.');
      // console.log('Validation failed: Invalid email address');
      isValid = false;
    }

    // Validate Password
    else if (!formData.password.trim()) {
      setError('Password is required.');
      console.log('Validation failed: Password is required');
      isValid = false;
    } else if (formData.password.length < 5) {
      setError('Password should be at least 5 characters.');
      // console.log('Validation failed: Password too short');
      isValid = false;
    }

    // Validate Confirm Password
    else if (formData.password !== formData.password2) {
      setError('Passwords do not match.');
      // console.log('Validation failed: Passwords do not match');
      isValid = false;
    }

    return isValid;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    if (!validateFormData()) {
      // setError('Invalid form data');
      return;
    }
    setShowModal(true); // Show the modal for confirmation
  };

  const handleModalConfirm = async () => {
    setLoading(true);
    setIsSubmitting(true);

    try {
      posthog.capture('Modal submit button clicked');
      // Fetch the CSRF token first
      console.log("Called posthog.capture");
      const csrfResponse = await makeApiRequest('csrf', null, 'GET');
      const csrfToken = csrfResponse.csrf_token;

      // Prepare headers with CSRF token
      const extraHeaders = {
        'x-csrf-token': csrfToken
      };

      const endpoint = 'signupnew'; // Use your actual endpoint
      const method = 'POST';

      // Now make the POST request with the CSRF token included in headers
      const response = await makeApiRequest(endpoint, null, method, formData, false, 4, {}, extraHeaders);

      if (response) {
        // Handle success, such as redirecting to a thank-you page or showing a success message
        window.location.href = '/signup/success';
      } else {
        throw new Error('Server returned no response');
      }
      // } catch (error) {
      //   console.error('Error during form submission:', error);
      //   setError(error);
      // } finally {
      //   setIsSubmitting(false);
      //   setLoading(false);
      //   setShowModal(false); // Hide the modal after operation
      // }
    } catch (error) {
      if (error instanceof ApiError) {
        const status = error.status;
        if (status === 401 || status === 403) {
          setError(error.message || (status === 401 ? 'Unauthorized access' : 'Forbidden access'));
        } else if (status === 422) {
          setError(error.message || 'Request Error');
        } else {
          setError(error.message || 'Request Error');
        }
      } else {
        setError('An unexpected error occurred');
        console.error('Unexpected error:', error);
      }
    } finally {
      setIsSubmitting(false);
      setLoading(false);
      setShowModal(false);
    }
  };


  const handleModalClose = () => {
    setShowModal(false); // Hide the modal without performing signup
  };


  const handleHpChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      hp: e.target.value
    }));
  };

  useEffect(() => {
    // Set the page title
    document.title = "Signup to Create PowerPoint Presentations with AI";

    // Set the page description
    setMetaTag('meta[name="description"]', 'content', "Signup to get a running headstart with AI-powered presentations");

    // Set the canonical URL
    setLinkTag('link[rel="canonical"]', 'href', `https://pptx.ai`);

    // Set the robots meta tag
    setMetaTag('meta[name="robots"]', 'content', 'index, follow');

    // Set the Open Graph meta tags
    setMetaTag('meta[property="og:title"]', 'content', "Signup to Create PowerPoint Presentations with AI");
    setMetaTag('meta[property="og:description"]', 'content', "Signup to get a running headstart with AI-powered presentations");

    setMetaTag('meta[property="og:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);

    // Set the Twitter meta tags
    setMetaTag('meta[name="twitter:title"]', 'content', "Signup to Create PowerPoint Presentations with AI");
    setMetaTag('meta[name="twitter:description"]', 'content', "Signup to get a running headstart with AI-powered presentations");
    setMetaTag('meta[name="twitter:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);
  }, []); // Dependencies array ensures this runs when `post` object changes

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex justify-center items-center h-10">
            <Logo className="logo" width={12} height={12} />
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-blue-500">
            Sign up to craft engaging slides quicker than a coffee break
          </h2>
          {error && <Error error={error} />}

        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="full_name" className="block text-sm font-medium leading-6 text-gray-900">
                Full Name
              </label>
              <div className="mt-2">
                <input
                  id="full_name"
                  name="full_name"
                  type="text"
                  placeholder='Full Name'
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="email_address" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email_address"
                  name="email_address"
                  type="email"
                  placeholder='Do not use a disposable email address'
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder='Set a password'
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password2" className="block text-sm font-medium leading-6 text-gray-900">
                Confirm Password
              </label>
              <div className="mt-2">
                <input
                  id="password2"
                  name="password2"
                  type="password"
                  placeholder='Enter the password again'
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <input
              type="hidden"
              name="hp"
              value={formData.hp} // Use formData.hp as the value
              onChange={handleHpChange} // Update the 'hp' value in formData
            />


            <div>
              <button
                type="submit"
                disabled={loading}
                className="flex w-full justify-center rounded-md bg-blue-500 bg-opacity-88 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                {loading ? (
                  <>
                    <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6-3.309 0-6-2.691-6-6H6c0 4.411 3.589 8 8 8z" />
                    </svg>
                    Signing up...
                  </>
                ) : (
                  'Sign up'
                )}
              </button>


            </div>
            <p className="mt-10 text-center text-sm text-gray-500">
              Already have an account?{' '}
              <a href="/signin" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                Sign in here!
              </a>
            </p>

          </form>
        </div>
      </div>

      <SignupModal
        isOpen={showModal}
        onConfirm={handleModalConfirm}
        onClose={handleModalClose}
        name={formData.full_name}
        email={formData.email_address}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
      />

    </>
  )
}
