import { useState } from 'react'
import makeApiRequest from './util/diffute';  // Importing makeApiRequest
import ApiError from './errors/pptx';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header.js';
import Footer from './components/Footer.js';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Contact() {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [hp, setHp] = useState('');
    const [error, setError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Fetch the CSRF token first
            const csrfResponse = await makeApiRequest('csrf', null, 'GET');
            const csrfToken = csrfResponse.csrf_token;

            // Prepare headers with CSRF token
            const extraHeaders = {
                'x-csrf-token': csrfToken
            };

            const endpoint = 'contact';  // The endpoint for contact form submissions
            const method = 'POST';
            const data = {
                email_address: email,
                first_name: firstName,
                last_name: lastName,
                message: message,
                hp: hp  // Presumably a honeypot field for spam protection
            };
            // Passing extraHeaders to makeApiRequest
            const response = await makeApiRequest(endpoint, navigate, method, data, false, 4, {}, extraHeaders);

            if (response) {
                setSubmitSuccess(true);
            } else {
                throw new ApiError('Something went wrong', 500);
            }
        } catch (error) {
            if (error instanceof ApiError) {
                const status = error.status;
                if (status >= 400 && status < 500) {
                    setError(error.message || 'Client error');
                } else if (status >= 500) {
                    setError(error.message || 'Server error');
                } else {
                    setError('An unexpected error occurred');
                }
                console.error(`Error ${status}:`, error.message);
            } else {
                setError('An unexpected error occurred');
                console.error('Unexpected error:', error);
            }
        }
    };

    return (

        <div>
            <Header />
            {submitSuccess ? (

                <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8" >
                    <div
                        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>

                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-blue-600 sm:text-2xl">Contact Us</h2>
                        <p className="mt-2 text-lg leading-8 text-blue-600">
                            We got it.  Thank you.
                        </p>

                        <a href="/create">
                            <button
                                type="button"
                                className="mt-4 rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                            >
                                Create Some Slides?
                            </button>
                        </a>


                    </div>
                </div>
            ) :
                (<div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div
                        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-blue-600 sm:text-4xl">Contact Us</h2>
                        <p className="mt-2 text-lg leading-8 text-blue-600">
                            Have a question?  We'd love to hear from you.
                        </p>
                    </div>
                    <form onSubmit={handleSubmit} method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-blue-900">
                                    First name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        value={firstName} // Bind to state
                                        onChange={e => setFirstName(e.target.value)} // Update state
                                        required
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-blue-900 shadow-sm ring-1 ring-inset ring-blue-300 placeholder:text-blue-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-blue-900">
                                    Last name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        value={lastName} // Bind to state
                                        onChange={e => setLastName(e.target.value)} // Update state
                                        required
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-blue-900 shadow-sm ring-1 ring-inset ring-blue-300 placeholder:text-blue-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-blue-900">
                                    Email
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        value={email} // Bind to state
                                        onChange={e => setEmail(e.target.value)} // Update state
                                        required
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-blue-900 shadow-sm ring-1 ring-inset ring-blue-300 placeholder:text-blue-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-blue-900">
                                    Message
                                </label>
                                <div className="mt-2.5">
                                    <textarea
                                        name="message"
                                        id="message"
                                        rows={4}
                                        value={message} // Bind to state
                                        onChange={e => setMessage(e.target.value)} // Update state
                                        required
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-blue-900 shadow-sm ring-1 ring-inset ring-blue-300 placeholder:text-blue-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="">
                                <label htmlFor="hp" className="block text-sm font-semibold leading-6 text-blue-900 invisible">
                                    hp
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="hp"
                                        id="hp"
                                        value={hp}
                                        onChange={(e) => setHp(e.target.value)}
                                        style={{ display: 'none' }}  // This makes it hidden
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="mt-10">
                            <button
                                type="submit"
                                className="block w-full rounded-md bg-blue-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                )}

            <Footer />
        </div>


    )
}
