
import { Fragment } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'
import { Tab } from '@headlessui/react'
import CheckoutForm from './CheckoutForm.js';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';

const reviews = {
    average: 4,
    featured: [
        {
            id: 1,
            rating: 5,
            content: `<p>PPTX.ai definitely put together a helpful starting point for the presentation I have to prepare. It's always fun to discover a new tool to make life easier, so thank you!</p>`,
            date: 'September 27, 2023',
            datetime: '2021-09-27',
            author: 'Mara K., CEO',
        },
        {
            id: 2,
            rating: 5,
            content: `<p>Very well transferred from the document into a presentation!</p>`,
            date: 'October 26, 2023',
            datetime: '2023-10-26',
            author: 'Ingo L., Managing Partner',
        },
        {
            id: 3,
            rating: 5,
            content: `<p>Both presentations PPTX.ai sent me were awesome! I was super impressed.</p>`,
            date: 'October 3, 2023',
            datetime: '2023-10-03',
            author: 'Jenny L., Human Resources Leader',
        },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function estimateTime(totalCharacters, includeSpeakerNotes) {
    // Constants
    const charsPerMinute = 500; // Average reading speed (characters per minute)
    const minutesPerPage = 4; // Time to summarize and extract key points per page
    const wordsPerMinuteWriting = 30; // Estimated writing speed for speaker's notes (words per minute)
    const wordsPerTalkTrack = 350; // Estimated words for a 2-3 minute talk track per slide
    const presentationCreationTime = 8.3; // Time to create a presentation (hours)

    // Estimates
    const readingTime = totalCharacters / charsPerMinute / 60; // Convert to hours
    const numberOfPages = totalCharacters / 1500; // Assuming ~1500 characters per page
    const summarizationTime = numberOfPages * minutesPerPage / 60; // Convert to hours
    const numberOfSlides = numberOfPages; // Assuming 1 slide per summarized page

    let writingTimeForTalkTrack = 0;
    if (includeSpeakerNotes) {
        writingTimeForTalkTrack = (wordsPerTalkTrack * numberOfSlides) / wordsPerMinuteWriting / 60; // Time to write speaker's notes (hours)
    }

    // Total time estimate
    const totalTime = readingTime + summarizationTime + writingTimeForTalkTrack;

    const totalHours = (totalTime + presentationCreationTime).toFixed(1);
    return totalHours;
}

// Example usage:
// const totalCharacters = 8252;
// const includeSpeakerNotes = true;
// const estimatedHours = estimateTime(totalCharacters, includeSpeakerNotes);
// console.log(`Estimated time: ${estimatedHours} hours`);


// function estimateTime(totalCharacters) {
//     // Constants
//     const charsPerMinute = 500; // Average reading speed (characters per minute)
//     const minutesPerPage = 4; // Time to summarize and extract key points per page

//     // Estimates
//     const readingTime = totalCharacters / charsPerMinute;
//     const numberOfPages = totalCharacters / 1500; // Assuming ~1500 characters per page
//     const summarizationTime = numberOfPages * minutesPerPage;
//     const presentationCreationTime = 8.3;

//     // Total time estimate
//     const totalTime = readingTime + summarizationTime;

//     const totalHours = (totalTime / 60 + presentationCreationTime).toFixed(1)
//     return totalHours;
// }


export default function CheckoutDynamic({ clientSecret, returnUrl, totalDue, characterCount, completeNotes, stripePromise }) {

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };


    return (
        <div className="bg-white">
            <div className="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-8xl lg:px-8">
                {/* Product */}
                <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                    {/* Product image */}
                    {/* <div className="lg:col-span-4 lg:row-end-1">
                        <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
                            <img src={product.imageSrc} alt={product.imageAlt} className="object-cover object-center" />
                        </div>
                    </div> */}

                    {/* Product details */}
                    <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
                        <div className="flex flex-col-reverse">
                            <div className="mt-4">
                                {/* <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Start building slides</h1> */}

                                {/* <h2 id="information-heading" className="sr-only">
                                    Product information
                                </h2> */}
                                <p className="mt-2 text-sm text-gray-500">
                                    {/* Version {product.version.name} (Updated{' '}
                                    <time dateTime={product.version.datetime}>{product.version.date}</time>) */}
                                </p>
                            </div>
                        </div>

                        <Elements options={options} stripe={stripePromise}>
                            <div>
                                <CheckoutForm returnUrl={returnUrl} totalDue={totalDue} clientSecret={clientSecret} stripePromise={stripePromise} />
                            </div>
                        </Elements>



                        {/* <div className="mt-10 border-t border-gray-200 pt-10">
                            <h3 className="text-sm font-medium text-gray-900">License</h3>
                            <p className="mt-4 text-sm text-gray-500">
                                {license.summary}{' '}
                                <a href={license.href} className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Read full license
                                </a>
                            </p>
                        </div> */}

                        {/* <div className="mt-10 border-t border-gray-200 pt-10">
                            <h3 className="text-sm font-medium text-gray-900">Share</h3>
                            <ul role="list" className="mt-4 flex items-center space-x-6">
                                <li>
                                    <a href="#" className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Share on Facebook</span>
                                        <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Share on Instagram</span>
                                        <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                            <path
                                                fillRule="evenodd"
                                                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Share on Twitter</span>
                                        <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>

                    <div className="mx-auto mt-2 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">

                        <div>
                            <div className="prose text-xl mt-4 text-gray-900">
                                {characterCount > 0 ? (
                                    completeNotes ? (
                                        `$${totalDue} could save you ${estimateTime(characterCount, true)} hours of work reading through documents, summarizing each page and then creating a presentation with speakers notes from the summary! The total amount of text across your docs is ${characterCount} characters long!`
                                    ) : (
                                        `$${totalDue} could save you ${estimateTime(characterCount, false)} hours of work reading through documents, summarizing each page and then creating a presentation! The total amount of text across your docs is ${characterCount} characters long!`
                                    )
                                ) : completeNotes ? (
                                    `$${totalDue} could save you at least 20.5 hours creating a presentation with speakers notes!`
                                ) : (
                                    `$${totalDue} could save you at least 8.3 hours of work creating a presentation!`
                                )}
                            </div>
                        </div>

                        <Tab.Group as="div">

                            <Tab.Panels as={Fragment}>
                                <Tab.Panel className="-mb-10">
                                    <h3 className="sr-only">Customer Reviews</h3>

                                    {reviews.featured.map((review, reviewIdx) => (
                                        <div key={review.id} className="flex space-x-4 text-sm text-gray-500">
                                            {/* <div className="flex-none py-10">
                                                <img src={review.avatarSrc} alt="" className="h-10 w-10 rounded-full bg-gray-100" />
                                            </div> */}
                                            <div className={classNames(reviewIdx === 0 ? '' : 'border-t border-gray-200', 'py-10')}>
                                                <h3 className="font-medium text-gray-900">{review.author}</h3>
                                                <p>
                                                    <time dateTime={review.datetime}>{review.date}</time>
                                                </p>

                                                <div className="mt-4 flex items-center">
                                                    {[0, 1, 2, 3, 4].map((rating) => (
                                                        <StarIcon
                                                            key={rating}
                                                            className={classNames(
                                                                review.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                                                                'h-5 w-5 flex-shrink-0'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    ))}
                                                </div>
                                                <p className="sr-only">{review.rating} out of 5 stars</p>

                                                <div
                                                    className="prose prose-sm mt-4 max-w-none text-gray-500"
                                                    dangerouslySetInnerHTML={{ __html: review.content }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}
