import { useEffect } from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { setMetaTag, setLinkTag } from './util/meta.js';

const faqs = [
    {
        question: "How does PPTX.ai work?",
        answer:
            "PPTX.ai uses Artificial Intelligence to generate presentations compatible with Microsoft PowerPoint, Google Slides and Apple Keynote.  You can supply a text description of your subject and/or upload documents that support your topic.  We then use the description and documents as summarized input to generating your slides.",
    },
    {
        question: "What types of documents can I upload that can be used as input to generating slides on PPTX.ai?",
        answer:
            "PPTX.ai supports PDF, Microsoft Word and simple text documents.  You can also upload transcripts from web presentation platforms such as Zoom and WebEx.",
    },
    {
        question: "Is there a limit to the number of pages or number of documents that I can upload at once?",
        answer:
            "There is no limit to the size of documents, the number of pages in document or the total number of documents.",
    },
    {
        question: "How many slides does PPTX.ai generate in a single presentation?",
        answer:
            "PPTX.ai will generate an 18 slide presentation for you along with extra slides that you may find useful to customize as needed or simply discard.",
    },
    {
        question: "How long does it take to generate slides using PPTX.ai?",
        answer:
            "In many cases, PPTX.ai will generate a presentation and email it to you within an hour after submitting the request if you are not uploading documents to be used as input.  It takes longer to generate presentations when uploaded documents are used because of the need to process each and every page.",
    },
    {
        question: "I have a question that is not answered here.  How do I get in touch?",
        answer:
            "There's link to a contact form at the bottom of this page.",
    },
]

export default function FAQ() {

    useEffect(() => {
        // Set the page title
        document.title = "Frequently Asked Questions about Using AI to Generate PowerPoint and Google Slides";

        // Set the page description
        setMetaTag('meta[name="description"]', 'content', "The most important questions about using AI to generate content such as PowerPoint by summarizing Word, PDF and other documents");

        // Set the canonical URL
        setLinkTag('link[rel="canonical"]', 'href', `https://pptx.ai/faq`);

        // Set the robots meta tag
        setMetaTag('meta[name="robots"]', 'content', 'index, follow');

        // Set the Open Graph meta tags
        setMetaTag('meta[property="og:title"]', 'content', "Frequently Asked Questions about Using AI to Generate PowerPoint and Google Slides");
        setMetaTag('meta[property="og:description"]', 'content', "The most important questions about using AI to generate content such as PowerPoint by summarizing Word, PDF and other documents");

        setMetaTag('meta[property="og:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);

        // Set the Twitter meta tags
        setMetaTag('meta[name="twitter:title"]', 'content', "Frequently Asked Questions about Using AI to Generate PowerPoint and Google Slides");
        setMetaTag('meta[name="twitter:description"]', 'content', "The most important questions about using AI to generate content such as PowerPoint by summarizing Word, PDF and other documents");
        setMetaTag('meta[name="twitter:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);
    }, []); // Dependencies array ensures this runs when `post` object changes


    return (
        <div className="bg-white">
            <Header />
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                <div className="mx-auto max-w-4xl divide-y divide-blue-900/10">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-blue-600">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-blue-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-blue-600">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-blue-500">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
            <Footer />
        </div>
    )
}
