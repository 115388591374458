import React, { useState, useEffect } from 'react';

import speakersData from './SpeakersData';

export default function Templates({ selectedSpeakerName, setSelectedSpeakerName, customProfile, setCustomProfile }) {

    const [speakers, setSpeakers] = useState([]);

    useEffect(() => {
        // Set the templates data from the JSON file
        setSpeakers(speakersData);
    }, []);

    const handleTemplateSelect = (id) => {
        if (selectedSpeakerName === id) {
            // Deselect the speaker if it's already selected
            setSelectedSpeakerName('');
        } else {
            // Select the speaker and clear the customProfile textarea
            setSelectedSpeakerName(id);
            setCustomProfile('');
        }
    };


    return (
        <div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {speakers.map((speaker) => (
                        <article key={speaker.template} className="flex flex-col items-start justify-between">
                            <div className="relative w-full" onClick={() => handleTemplateSelect(speaker.template)}>
                                <img
                                    src={speaker.thumbnail}
                                    alt=""
                                    className={`w-full h-full rounded-2xl bg-gray-100 object-cover ${selectedSpeakerName === speaker.template ? 'border-4 border-indigo-600' : ''}`}
                                />
                                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                            </div>

                            <div className="max-w-xl">
                                <div className="group relative">
                                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                        <a href={speaker.href}>
                                            <span className="absolute inset-0" />
                                            {speaker.template}
                                        </a>
                                    </h3>
                                    {/* You can replace this with the appropriate description field from your JSON data */}
                                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{speaker.description}</p>
                                    <p className="text-sm font-medium text-gray-400">{speaker.price}</p>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
            {/* Add a free text field for custom speaker profile */}
            <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-8">
                <h2 className="text-lg font-semibold leading-6 text-gray-900">Or enter another well-known orator</h2>
                <textarea
                    className="w-full p-2 mt-2 border rounded-md"
                    placeholder="Enter your custom speaker profile here..."
                    value={customProfile}
                    onChange={(e) => {
                        // Clear the selected speaker when text is entered
                        setSelectedSpeakerName('');
                        setCustomProfile(e.target.value);
                    }}
                />
                <p className="text-sm font-medium text-gray-400">$29</p>

            </div>
        </div>
    );
}