import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Logo from './Logo.js'

import Error from './Error';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import makeApiRequest from './util/diffute';  // Importing makeApiRequest
import ApiError from './errors/pptx';
import { useNavigate } from 'react-router-dom';

export default function Activation() {

  const { activation_key } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const activateAccount = async () => {
      try {
        // First, fetch the CSRF token
        const csrfResponse = await makeApiRequest('csrf', navigate, 'GET');
        const csrfToken = csrfResponse.csrf_token;

        // Prepare headers with CSRF token
        const extraHeaders = {
          'x-csrf-token': csrfToken
        };

        const endpoint = `activatenew/${activation_key}`;
        // Include extraHeaders in makeApiRequest
        const response = await makeApiRequest(endpoint, navigate, 'GET', null, false, 4, {}, extraHeaders);

        if (response) {
          setSuccess(true);
          setTimeout(function () {
            navigate('/create');
          }, 3000);
        } else {
          throw new ApiError('Something went wrong', 500);
        }
      } catch (error) {
        if (error instanceof ApiError) {
          const status = error.status;
          if (status === 410) {
            setSuccess(true);
            setTimeout(function () {
              navigate('/create');
            }, 3000);
          } else if (status >= 400 && status < 500) {
            setError(error.message || 'Client error');
          } else if (status >= 500) {
            setError(error.message || 'Server error');
          } else {
            setError('An unexpected error occurred');
          }
          console.error(`Error ${status}:`, error.message);
        } else {
          setError('An unexpected error occurred');
          console.error('Unexpected error:', error);
        }
      }
    };

    activateAccount();
  }, [activation_key, navigate]); // Include navigate in the dependency array if it could change



  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo className="logo" width={12} height={12} />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-blue-500">
          Account activation
        </h2>
        {error && <Error error={error} />}
        {success && (
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <a href="/signin">
                <div className="ml-3">
                  <h3 className="text-md font-medium text-blue-800">Activation successful!</h3>
                  <div className="mt-2 text-md text-blue-700">
                    <p>Your account has been activated.  We'll redirect you in a few seconds to create your first presentation.</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
