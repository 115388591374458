import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Navigation from './Navigation.js'

import CheckoutDynamic from './components/CheckoutDynamic.js';
import Confirmation from './components/ConfirmationComponent.js'

import makeApiRequest from './util/diffute.js';
import ApiError from './errors/pptx';
import { useNavigate } from 'react-router-dom';

export default function Checkout() {
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.

    const navigate = useNavigate();
    const { deck_id } = useParams();
    const [deck, setDeck] = useState(null);  // State to hold the fetched data
    const [clientSecret, setClientSecret] = useState(null);  // State to hold the fetched data
    const [stripePk, setStripePk] = useState(null);  // State to hold the fetched data
    const [totalDue, setTotalDue] = useState(null);  // State to hold the fetched data
    const [error, setError] = useState(null); // New state variable for error handling
    const [stripePromise, setStripePromise] = useState(null); // New state variable for error handling
    const [returnUrl, setReturnUrl] = useState(null);
    const [credits, setCredits] = useState(null);  // State to hold the fetched data
    const [premiumCredits, setPremiumCredits] = useState(null);  // State to hold the fetched data
    const [characterCount, setCharacterCount] = useState(0);  // State to hold the fetched data

    useEffect(() => {
        // console.log("Updated characterCount: ", characterCount);
    }, [characterCount]);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const csrfResponse = await makeApiRequest('csrf', null, 'GET');
                const csrfToken = csrfResponse.csrf_token;

                // Prepare headers with CSRF token
                const extraHeaders = {
                    'x-csrf-token': csrfToken
                };

                const responseData = await makeApiRequest('checkoutnew/' + deck_id, navigate, 'POST', null, false, 4, {}, extraHeaders);
                if (responseData) {
                    setClientSecret(responseData.client_secret);
                    setStripePk(responseData.stripe_pk);
                    setTotalDue(responseData.total_due);
                    setDeck(responseData.deck);
                    setReturnUrl(responseData.return_url);
                    setCredits(responseData.user.credits);
                    setPremiumCredits(responseData.user.premium_credits);

                    if (responseData.stripe_pk) {
                        setStripePromise(loadStripe(`${responseData.stripe_pk}`));
                    } else {
                        console.log("stripePk: ", stripePk);
                    }

                    if (responseData.total_character_count) {
                        setCharacterCount(responseData.total_character_count);
                    }

                    localStorage.removeItem('formData');
                    setError(null);
                } else {
                    setError("API response is null or undefined.");
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    const status = error.status;
                    if (status >= 400 && status < 500) {
                        setError(error.message || 'Client error');
                    } else if (status >= 500) {
                        setError(error.message || 'Server error');
                    } else {
                        setError('An unexpected error occurred');
                    }
                    console.error(`Error ${status}:`, error.message);
                } else {
                    setError('An unexpected error occurred');
                    console.error('Unexpected error:', error);
                }
            }
        };

        fetchData();
    }, []);  // Dependency array updated to include variables used inside the useEffect


    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            {error ? (
                <div>Error: {error}</div>
            ) : (
                deck ? (
                    <div>
                        <Navigation />
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center">
                                <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900"></span>
                            </div>
                        </div>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="mx-auto max-w-3xl">
                                <div>
                                    <div>
                                        {deck ? (
                                            deck.deck_status === 'completed' ? (
                                                <Confirmation
                                                    customProfile={deck.custom_profile}
                                                    selectedSpeakerName={deck.speaker_name}
                                                    completeNotes={deck.complete_notes}
                                                    selectedTemplateName={deck.pptx_template}
                                                    description={deck.description}
                                                    deck_status={deck.deck_status}
                                                    email_address={deck.email_address}
                                                    credits={credits}
                                                    premiumCredits={premiumCredits}
                                                />
                                            ) : deck.deck_status === 'processing' ? (
                                                deck.credits === true ? (
                                                    <Confirmation
                                                        customProfile={deck.custom_profile}
                                                        selectedSpeakerName={deck.speaker_name}
                                                        completeNotes={deck.complete_notes}
                                                        selectedTemplateName={deck.pptx_template}
                                                        description={deck.description}
                                                        deck_status={deck.deck_status}
                                                        email_address={deck.email_address}
                                                        credits={credits}
                                                        premiumCredits={premiumCredits}
                                                    />
                                                ) : stripePk && clientSecret ? (
                                                    <Elements options={options} stripe={stripePromise}>
                                                        <div>
                                                            <CheckoutDynamic returnUrl={returnUrl} totalDue={totalDue} characterCount={characterCount} completeNotes={deck.complete_notes} clientSecret={clientSecret} stripePromise={stripePromise} />
                                                        </div>
                                                    </Elements>
                                                ) : (
                                                    <div>Loading...</div>
                                                )
                                            ) : deck.deck_status === 'submitted' ? (
                                                deck.credits === true ? (
                                                    <Confirmation
                                                        customProfile={deck.custom_profile}
                                                        selectedSpeakerName={deck.speaker_name}
                                                        completeNotes={deck.complete_notes}
                                                        selectedTemplateName={deck.pptx_template}
                                                        description={deck.description}
                                                        deck_status={deck.deck_status}
                                                        email_address={deck.email_address}
                                                        credits={credits}
                                                        premiumCredits={premiumCredits}
                                                    />
                                                ) : stripePk && clientSecret ? (
                                                    <Elements options={options} stripe={stripePromise}>
                                                        <div>
                                                            <CheckoutDynamic returnUrl={returnUrl} totalDue={totalDue} characterCount={characterCount} completeNotes={deck.complete_notes} clientSecret={clientSecret} stripePromise={stripePromise} />
                                                        </div>
                                                    </Elements>
                                                ) : (
                                                    <div>Loading...</div>
                                                )
                                            ) : (
                                                <div>Invalid Status</div>
                                            )
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : stripePk && clientSecret ? (
                    <Elements options={options} stripe={stripePromise}>
                        <div>
                            <CheckoutDynamic returnUrl={returnUrl} totalDue={totalDue} characterCount={characterCount} completeNotes={deck.complete_notes} clientSecret={clientSecret} stripePromise={stripePromise} />
                        </div>
                    </Elements>
                ) : (
                    <div style={{
                        animation: 'rotate 1s infinite',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1000 // ensure it's on top of everything else
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgba(37, 99, 235, 1)" className="w-36 h-36" style={{ transformOrigin: 'center' }}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
                        </svg>
                    </div>
                )
            )}

        </div>
    );
}
