import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ Component, isAuthenticated }) => {
    const location = useLocation();
    const hasA = isAuthenticated();

    const redirectTo = `/signin?from=${location.pathname}`;

    return hasA ? <Component /> : <Navigate to={redirectTo} replace />;
};

export default ProtectedRoute;
