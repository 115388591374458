import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import dummyPost1 from './images/dummy-post.jpg'
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { getMediaFile } from './util/content.js';
import { setMetaTag, setLinkTag } from './util/meta.js';


function extractFirstSentences(content, sentenceCount) {
    const div = document.createElement('div');
    div.innerHTML = content;

    const sentences = div.innerText.split('. '); // Assuming sentences end with periods ('.')
    const firstSentences = sentences.slice(0, sentenceCount);

    return firstSentences.join('. ') + '.';
}

export default function BlogPost() {

    let { slug } = useParams();
    const [pages_json, setPages_json] = useState([])
    const [post, setBlogPost] = useState({
        title: '',  // Add other default properties here
        media: [],  // Add other default properties here
    })
    const [imageUrl, setImageUrl] = useState(''); // State for storing the image URL


    useEffect(() => {
        setPages_json(window?.pages ? window.pages : [])
    }, [])

    useEffect(() => {
        if (pages_json && pages_json.length && slug) {
            const blog = pages_json
                .filter((blog) => blog.page_type === 'post')
                .filter((blog) => blog.slug === slug)
            if (blog.length) {
                setBlogPost(blog[0]);

                // Set document title and meta description here
                document.title = blog[0].title;
                const descriptionMeta = document.querySelector('meta[name="description"]');
                if (descriptionMeta) {
                    descriptionMeta.setAttribute('content', extractFirstSentences(blog[0].content, 2));
                }

                const mediaFile = getMediaFile(blog[0].media);
                const mediaUrl = mediaFile ? `https://${mediaFile.link}` : dummyPost1; // Ensure the URL is correct
                setImageUrl(mediaUrl);
            }
        }
    }, [pages_json, slug]);

    useEffect(() => {
        // Set the page title
        document.title = post.title;

        // Set the page description
        setMetaTag('meta[name="description"]', 'content', ((post.custom?.description || post.excerpt) || '').slice?.(0, 150));

        // Set the canonical URL
        setLinkTag('link[rel="canonical"]', 'href', `https://pptx.ai/blog/${post.slug}`);

        // Set the robots meta tag
        setMetaTag('meta[name="robots"]', 'content', 'index, follow');

        // Set the Open Graph meta tags
        setMetaTag('meta[property="og:title"]', 'content', post.title);
        setMetaTag('meta[property="og:description"]', 'content', ((post.custom?.description || post.excerpt) || '').slice?.(0, 150));

        setMetaTag('meta[property="og:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);

        // Set the Twitter meta tags
        setMetaTag('meta[name="twitter:title"]', 'content', post.title);
        setMetaTag('meta[name="twitter:description"]', 'content', (post.custom?.description || post.excerpt)?.slice(0, 150));
        setMetaTag('meta[name="twitter:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);
    }, [post]); // Dependencies array ensures this runs when `post` object changes


    return (
        <div>
            <div className="bg-white px-6 py-32 lg:px-8">
                <Header />
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        <Link to={`/blog/${slug}`}>{post.title}</Link>
                    </h1>

                    <figure className="mt-16">
                        <img
                            className="aspect-video rounded-xl bg-gray-50 object-cover"
                            src={imageUrl}
                            alt={post.title || ""}
                        />
                    </figure>
                    <div className="mt-16 max-w-2xl">
                        <p className="mt-6" dangerouslySetInnerHTML={{ __html: post.content }}></p>
                    </div>
                    <div className="flex justify-center mt-6">
                        <a href={`https://pptx.ai/signup?ref=${slug}`}>
                            <button
                                type="button"
                                className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-900"
                            >
                                <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                Try it now
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
