import { useEffect, useState } from 'react'
import dummyPost1 from './images/dummy-post.jpg'
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { getMediaFile } from './util/content.js';
import { setMetaTag, setLinkTag } from './util/meta.js';

function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}

function extractFirstSentences(content, sentenceCount) {
    const div = document.createElement('div');
    div.innerHTML = content;

    const sentences = div.innerText.split('. '); // Assuming sentences end with periods ('.')
    const firstSentences = sentences.slice(0, sentenceCount);

    return firstSentences.join('. ') + '.';
}


export default function Blog() {

    const [pages_json, setPages_json] = useState([])
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        setPages_json(window?.pages ? window.pages : [])
    }, [])

    useEffect(() => {
        if (pages_json && pages_json.length) {
            const onlyPosts = pages_json.filter((page) => page.page_type === 'post' && page.slug_prefix === 'blog');
            setBlogs(onlyPosts);
        }
    }, [pages_json])

    useEffect(() => {
        // Set the page title
        document.title = "Presenting: The PPTX.ai Blog";

        // Set the page description
        setMetaTag('meta[name="description"]', 'content', "The official blog of PPTX.ai");

        // Set the canonical URL
        setLinkTag('link[rel="canonical"]', 'href', `https://pptx.ai/blog`);

        // Set the robots meta tag
        setMetaTag('meta[name="robots"]', 'content', 'index, follow');

        // Set the Open Graph meta tags
        setMetaTag('meta[property="og:title"]', 'content', "Presenting: The PPTX.ai Blog");
        setMetaTag('meta[property="og:description"]', 'content', "The official blog of PPTX.ai");

        setMetaTag('meta[property="og:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);

        // Set the Twitter meta tags
        setMetaTag('meta[name="twitter:title"]', 'content', "Presenting: The PPTX.ai Blog");
        setMetaTag('meta[name="twitter:description"]', 'content', "The official blog of PPTX.ai");
        setMetaTag('meta[name="twitter:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);

    }, []); // Dependencies array ensures this runs when `post` object changes


    return (
        <div>
            <div className="bg-white py-24 sm:py-32">
                <Header />
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-blue-600 sm:text-4xl">Presenting: The PPTX.ai Blog</h2>
                        <p className="mt-2 text-lg leading-8 text-blue-600">
                            Use AI to build and deliver amazing presentations
                        </p>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {blogs.map((post) => {
                            // Get the correct media file for each post
                            const mediaFile = getMediaFile(post.media);
                            const mediaUrl = mediaFile ? `https://${mediaFile.link}` : dummyPost1; // Ensure the URL is correct and prepend protocol

                            return (
                                <a href={'/blog/' + post.slug} key={post.page_id}>
                                    <article className="flex flex-col items-start justify-between">
                                        <div className="relative w-full">
                                            <img
                                                src={mediaUrl}
                                                alt={post.title || 'Blog Image'}
                                                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                            />
                                            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="max-w-xl">
                                            <div className="mt-8 flex items-center gap-x-4 text-xs">
                                                <time dateTime={post.page_modified} className="text-gray-500">
                                                    {formatDate(post.page_modified)}
                                                </time>
                                            </div>
                                            <div className="group relative">
                                                <h3 className="mt-3 text-lg font-semibold leading-6 text-blue-600 group-hover:text-gray-600">
                                                    <span className="absolute inset-0" />
                                                    {post.title}
                                                </h3>
                                                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-500" dangerouslySetInnerHTML={{ __html: extractFirstSentences(post.content, 3) }}></p>
                                            </div>
                                        </div>
                                    </article>
                                </a>
                            );
                        })}


                    </div>
                </div>
            </div >
            <Footer />
        </div>


    )

}
