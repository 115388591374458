import Cookies from 'js-cookie';

function isAuthenticated() {
  // Retrieve the value of the 'a' cookie
  const a = Cookies.get('a');

  // Return true if the 'a' cookie exists and is not empty, false otherwise
  return !!a;
}

export default isAuthenticated;
