import { useState, useEffect } from 'react';

const CookieConsentBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('userConsent');
        if (consent === null) {
            setShowBanner(true);
        }
    }, []);

    const handleConsent = () => {
        localStorage.setItem('userConsent', 'true');
        setShowBanner(false);
        // Potentially trigger a full app re-render or update context
        // if the rest of the app needs to react to this change.
    };

    const handleDeny = () => {
        localStorage.setItem('userConsent', 'false');
        setShowBanner(false);
        // Additional handling if you need to disable certain features
    };

    return showBanner && (
        <div className="fixed inset-x-0 bottom-0 z-50 bg-gray-800 text-white p-4 flex justify-between items-center">
            <p>This website uses cookies to improve our products by capturing bugs, etc.</p>
            <div>
                <button className="mr-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-2 rounded" onClick={handleConsent}>
                    Okay
                </button>
                <button className="bg-transparent hover:bg-blue-100 text-blue-500 hover:text-blue-700 py-2 px-2 rounded underline" onClick={handleDeny} style={{ border: 'none' }}>
                    Not okay
                </button>
            </div>
        </div>
    );
};

export default CookieConsentBanner;
