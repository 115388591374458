import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import dummyPost1 from './images/dummy-post.jpg'
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { setMetaTag, setLinkTag } from './util/meta.js';

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function extractFirstSentences(content, sentenceCount) {
    const div = document.createElement('div');
    div.innerHTML = content;

    const sentences = div.innerText.split('. '); // Assuming sentences end with periods ('.')
    const firstSentences = sentences.slice(0, sentenceCount);

    return firstSentences.join('. ') + '.';
}



export default function GalleryPost() {

    let { slug } = useParams();
    const [pages_json, setPages_json] = useState([])
    const [post, setBlogPost] = useState({
        title: '',  // Add other default properties here
        media: [],  // Add other default properties here
    })

    const createMarkup = (html) => {
        return { __html: html };
    }

    const extractIframeAndText = (content) => {
        const doc = new DOMParser().parseFromString(content, 'text/html');
        console.log("doc: ", doc);
        let iframeString = null;
        let iframe = doc.querySelector('iframe');
        let iframeStringMatch = null;
        let iframeProcessed = false;

        // 1. Direct iframe Match
        if (!iframe) {
            iframeStringMatch = doc.body.innerHTML.match(/<iframe[^>]*>.*?<\/iframe>/);
            // 2. Escaped iframe Match (only if direct match failed)
            if (!iframeStringMatch) {
                iframeStringMatch = doc.body.innerHTML.match(/&lt;iframe.*?&lt;\/iframe&gt;/);
                if (iframeStringMatch) {
                    iframeString = decodeHtml(iframeStringMatch[0]);
                }
            }

            // 3. Raw Content Match (only if both previous matches failed)
            if (!iframeStringMatch) {
                iframeStringMatch = doc.body.innerHTML.match(/\"<iframe[^>]*>.*?<\/iframe>\"/);
                if (iframeStringMatch) {
                    iframeString = iframeStringMatch[0];
                }
            }

            // 4. Match from raw content (only if all previous matches failed)
            if (!iframeStringMatch) {
                iframeStringMatch = content.match(/\"?<iframe[^>]*>.*?<\/iframe>\"?/);
                if (iframeStringMatch) {
                    iframeString = iframeStringMatch[0];
                }
            }

            // If we got iframeString, insert it into the doc to get the iframe element
            if (iframeString) {
                const wrapperDiv = doc.createElement('div');
                wrapperDiv.innerHTML = iframeString;
                doc.body.appendChild(wrapperDiv);
                iframe = wrapperDiv.querySelector('iframe');
            }

        }

        let textAfterIframe = '';

        if (iframe && !iframeProcessed) {
            textAfterIframe = doc.querySelector('blockquote').innerHTML;
        }

        return {
            iframe: iframe ? iframe.outerHTML : '',
            content: doc.body.innerHTML,
            textAfterIframe
        };

    }


    const { iframe, content, textAfterIframe } = useMemo(() => {
        if (post.content) {
            return extractIframeAndText(post.content);
        }
        return { iframe: null, content: null, textAfterIframe: null };
    }, [post.content]);


    useEffect(() => {
        setPages_json(window?.pages ? window.pages : [])
    }, [])

    useEffect(() => {
        if (pages_json && pages_json.length && slug) {
            const blog = pages_json
                .filter((blog) => blog.page_type === 'post')
                .filter((blog) => blog.slug === slug)
                .filter((blog) => blog.slug_prefix === 'gallery')
            if (blog.length) {
                setBlogPost(blog[0]);

                // Set document title and meta description here
                document.title = blog[0].title;
                const descriptionMeta = document.querySelector('meta[name="description"]');
                if (descriptionMeta) {
                    descriptionMeta.setAttribute('content', extractFirstSentences(blog[0].content, 2));
                }
            }
        }
    }, [pages_json, slug]);

    useEffect(() => {
        // Set the page title
        document.title = post.title;

        // Set the page description
        setMetaTag('meta[name="description"]', 'content', ((post.custom?.description || post.excerpt) || '').slice?.(0, 150));

        // Set the canonical URL
        setLinkTag('link[rel="canonical"]', 'href', `https://pptx.ai/gallery/${post.slug}`);

        // Set the robots meta tag
        setMetaTag('meta[name="robots"]', 'content', 'index, follow');

        // Set the Open Graph meta tags
        setMetaTag('meta[property="og:title"]', 'content', post.title);
        setMetaTag('meta[property="og:description"]', 'content', ((post.custom?.description || post.excerpt) || '').slice?.(0, 150));

        setMetaTag('meta[property="og:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);

        // Set the Twitter meta tags
        setMetaTag('meta[name="twitter:title"]', 'content', post.title);
        setMetaTag('meta[name="twitter:description"]', 'content', (post.custom?.description || post.excerpt)?.slice(0, 150));
        setMetaTag('meta[name="twitter:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);
    }, [post]); // Dependencies array ensures this runs when `post` object changes

    // const { iframe, content, textAfterIframe } = extractIframeAndText(post.content);
    const extractedContent = post.content ? extractIframeAndText(post.content) : null;

    return (
        <div>
            <div className="bg-white px-6 py-32 lg:px-8">
                <Header />
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <h1 className="mt-2 text-center text-3xl font-bold tracking-tight text-blue-600 sm:text-4xl">
                        <Link to={`/gallery/${slug}`}>{post.title}</Link>
                    </h1>

                    <div className="mt-16 iframe-container" dangerouslySetInnerHTML={createMarkup(iframe)}></div>

                    <div className="mt-16 text-container">
                        <p className="mt-6" dangerouslySetInnerHTML={createMarkup(textAfterIframe)}></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
