import axios from 'axios';
import * as Sentry from '@sentry/react';
import ApiError from '../errors/pptx';

async function makeApiRequest(endpoint, navigateCallback, method = 'GET', data = null, isFileUpload = false, maxRetries = 4, queryParams = {}, extraHeaders = {}) {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const requestOptions = {
        method,
        credentials: 'include',
        headers: {
            'x-client-domain': 'pptx.ai',
            ...extraHeaders // Add any extra headers provided
        }
    };

    if (data && (method === 'POST' || method === 'PUT' || method === 'PATCH' || method === 'DELETE')) {
        if (!isFileUpload) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(data);
        } else {
            requestOptions.body = data;
        }
    }

    const buildQueryString = params => {
        return Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
    };

    const queryString = buildQueryString(queryParams);

    const url = `${baseUrl}/${endpoint}${queryString ? `?${queryString}` : ''}`;

    // Retry logic for Axios
    for (let attempt = 0; attempt <= maxRetries; attempt++) {
        try {
            const axiosOptions = { ...requestOptions, withCredentials: true };
            if (axiosOptions.body) {
                axiosOptions.data = axiosOptions.body;
                delete axiosOptions.body;
            }
            const response = await axios(url, axiosOptions);
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            } else if (response.status === 401) {
                // Handle server redirect to /signin
                if (navigateCallback) {
                    navigateCallback('/signin');
                }
            } else {
                console.log(`Request failed with status ${response.status}`);
                throw new ApiError(`Request failed with status ${response.status}`, response.status);
            }
        } catch (axiosError) {
            // console.log("There was an axiosError: ", axiosError.response.status);
            if (axios.isAxiosError(axiosError)) {
                if (axiosError.response && axiosError.response.status === 403) {
                    let errorMessage = `Request failed with status ${axiosError.response.status}`;
                    if (axiosError.response.data && axiosError.response.data.detail) {
                        errorMessage = typeof axiosError.response.data.detail === 'string' ?
                            axiosError.response.data.detail :
                            'That request failed.';
                    }
                    throw new ApiError(errorMessage, axiosError.response.status);
                }
                else if (axiosError.response && axiosError.response.status === 401) {
                    // Handle server redirect to /signin
                    // console.log("Going to redirect to signin ...")
                    if (navigateCallback) {
                        navigateCallback('/signin');
                    }
                } else {
                    let errorMessage = `Request failed with status ${axiosError.response.status}`;
                    if (axiosError.response.data && axiosError.response.data.detail) {
                        errorMessage = typeof axiosError.response.data.detail === 'string' ?
                            axiosError.response.data.detail :
                            'That request failed.';
                    }
                    throw new ApiError(errorMessage, axiosError.response.status);
                }
            } else if (axiosError.code === 'ERR_NETWORK') {
                // If it's a network error, try again
                // console.log("The Axios error is ERR_NETWORK");
                if (attempt < maxRetries) {
                    continue;
                }
            } else {
                throw new ApiError('An unexpected error occurred', 500);
            }
        }
    }

    // Retry logic for Fetch
    for (let attempt = 0; attempt <= maxRetries; attempt++) {
        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                return response.json();

            } else if (response.status === 401) {
                // Handle server redirect to /signin
                if (navigateCallback) {
                    navigateCallback('/signin');
                }
            }
            else {
                const errorData = await response.json().catch(() => ({}));
                const errorMessage = typeof errorData.detail === 'string' ? errorData.detail : 'That request failed.  You may need to retry.';
                throw new ApiError(errorMessage, response.status);
            }
        } catch (fetchError) {
            if (fetchError instanceof ApiError) {
                throw fetchError;
            } else if (fetchError instanceof Response && fetchError.status === 401) {
                // Handle server redirect to /signin
                if (navigateCallback) {
                    navigateCallback('/signin');
                }
            } else {
                Sentry.withScope((scope) => {
                    scope.setExtra("error", fetchError);
                    Sentry.captureException(fetchError);
                });

                // Check if the error is a network error
                if (fetchError.name === 'TypeError' && attempt < maxRetries) {
                    continue;  // Retry if it's a network error and we haven't hit max retries
                } else {
                    throw new ApiError('An unexpected error occurred', 500);
                }
            }
        }

    }

}

export default makeApiRequest;