import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import posthog from 'posthog-js';

import { LanguageProvider } from './util/LanguageProvider'; // Import the LanguageProvider
import { PostHogProvider } from 'posthog-js/react'
import { FeatureFlagProvider } from './context/FeatureFlagContext'; // Import the provider

let Sentry;
try {
    Sentry = require("@sentry/react");
    Sentry.init({
        dsn: "https://43527f80f9acf7ea76d956f7f6c86f0a@o368315.ingest.sentry.io/4505886370693120",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", "https://app.pptx.ai"],
            }),
            new Sentry.Replay({
                networkDetailAllowUrls: ['https://app.pptx.ai'],
                networkRequestHeaders: ['Origin', 'Access-Control-Request-Method', 'Access-Control-Request-Headers'],
                networkResponseHeaders: ['Access-Control-Allow-Origin', 'Access-Control-Allow-Methods', 'Access-Control-Allow-Headers', 'Access-Control-Allow-Credentials', 'Access-Control-Expose-Headers', 'Access-Control-Max-Age'],
            }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
} catch (error) {
    console.error("Failed to initialize Sentry:", error);
    // You could implement additional logging or error handling here if needed
}

const container = document.getElementById("app");
const root = createRoot(container);

const userConsent = localStorage.getItem('userConsent');
// if (userConsent && userConsent === 'true') {
//     console.log("User HAS consented to analytics");
//     posthog.init('phc_iq0zj4ZGeJSSGkLof8V8DnPqlYiIdQfFgDmjve7wXTJ', {
//         api_host: 'https://app.posthog.com',
//         autocapture: false // start without autocapture to wait for user consent
//     });
//     posthog.onFeatureFlags(() => {
//         // Now safe to handle feature flags here or broadcast readiness
//         const flagValue = posthog.getFeatureFlag('homepage-conversion');
//         console.log('Feature flag value:', flagValue);
//     });
// } else {
//     console.log("User has NOT consented to analytics");
// }

root.render(
    <React.StrictMode>
        <LanguageProvider>
            <PostHogProvider client={posthog}>
                <FeatureFlagProvider>
                    <App userConsent={userConsent} />
                </FeatureFlagProvider>
            </PostHogProvider>
        </LanguageProvider>
    </React.StrictMode>
);


// // Then render your application
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
