import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import Logo from './Logo.js'

export default function SignupSuccess() {
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <Logo className="logo" width={12} height={12} />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-blue-600">
                    Almost done!
                </h2>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="rounded-md bg-blue-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-blue-800">One more step!</h3>
                            <div className="mt-2 text-sm text-blue-700">
                                <p>Check your email to confirm your account.  Check your spam too!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
