export default function Error({ error }) {
  let errorMessage;

  if (typeof error === 'string') {
    errorMessage = error;
  } else if (Array.isArray(error)) {
    // If error is an array, join all error messages into a single string
    errorMessage = error.map(e => e.msg).join(', ');
  } else {
    errorMessage = 'An unknown error occurred';
  }

  // console.log("errorMessage:  " + errorMessage);

  // Rest of the component

  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      <span className="block sm:inline">{errorMessage}</span>
    </div>
  );
}
