import { useEffect, useState } from 'react'
import dummyPost1 from './images/dummy-post.jpg'
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { setMetaTag, setLinkTag } from './util/meta.js';

function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}

function extractFirstSentences(content, sentenceCount) {
    const div = document.createElement('div');
    div.innerHTML = content;

    // Use a regular expression to split sentences based on common punctuation marks
    const sentences = div.innerText.split(/[.!?]/);

    // Remove empty strings from the resulting array
    const nonEmptySentences = sentences.filter(sentence => sentence.trim() !== '');

    // Extract the first N sentences
    const firstSentences = nonEmptySentences.slice(0, sentenceCount);

    // Join the first sentences and add the appropriate punctuation
    const joinedSentences = firstSentences.join('. ').trim() + '.';

    return joinedSentences;
}


function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

const extractIframeAndText = (content) => {
    const doc = new DOMParser().parseFromString(content, 'text/html');
    let iframeString = null;
    let iframe = doc.querySelector('iframe');
    let iframeStringMatch = null;
    let iframeProcessed = false;

    // 1. Direct iframe Match
    if (!iframe) {
        iframeStringMatch = doc.body.innerHTML.match(/<iframe[^>]*>.*?<\/iframe>/);
        // 2. Escaped iframe Match (only if direct match failed)
        if (!iframeStringMatch) {
            iframeStringMatch = doc.body.innerHTML.match(/&lt;iframe.*?&lt;\/iframe&gt;/);
            if (iframeStringMatch) {
                iframeString = decodeHtml(iframeStringMatch[0]);
            }
        }

        // 3. Raw Content Match (only if both previous matches failed)
        if (!iframeStringMatch) {
            iframeStringMatch = doc.body.innerHTML.match(/\"<iframe[^>]*>.*?<\/iframe>\"/);
            if (iframeStringMatch) {
                iframeString = iframeStringMatch[0];
            }
        }

        // 4. Match from raw content (only if all previous matches failed)
        if (!iframeStringMatch) {
            iframeStringMatch = content.match(/\"?<iframe[^>]*>.*?<\/iframe>\"?/);
            if (iframeStringMatch) {
                iframeString = iframeStringMatch[0];
            }
        }

        // If we got iframeString, insert it into the doc to get the iframe element
        if (iframeString) {
            const wrapperDiv = doc.createElement('div');
            wrapperDiv.innerHTML = iframeString;
            doc.body.appendChild(wrapperDiv);
            iframe = wrapperDiv.querySelector('iframe');
        }

    }

    let textAfterIframe = '';
    if (iframe && !iframeProcessed) {
        textAfterIframe = doc.querySelector('blockquote').textContent;
    }

    return {
        iframe: iframe ? iframe.outerHTML : '',
        content: doc.body.innerHTML,
        textAfterIframe
    };

}


export default function Gallery() {

    const [pages_json, setPages_json] = useState([])
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        setPages_json(window?.pages ? window.pages : [])
    }, [])

    useEffect(() => {
        if (pages_json && pages_json.length) {
            const onlyPosts = pages_json.filter((page) => page.page_type === 'post' && page.slug_prefix === 'gallery');
            setBlogs(onlyPosts);
        }
    }, [pages_json])


    useEffect(() => {
        // Set the page title
        document.title = "Examples of Presentations Generated using Artificial Intelligence";

        // Set the page description
        setMetaTag('meta[name="description"]', 'content', "Gallery of presentations made with AI");

        // Set the canonical URL
        setLinkTag('link[rel="canonical"]', 'href', `https://pptx.ai/gallery`);

        // Set the robots meta tag
        setMetaTag('meta[name="robots"]', 'content', 'index, follow');

        // Set the Open Graph meta tags
        setMetaTag('meta[property="og:title"]', 'content', "Examples of Presentations Generated using Artificial Intelligence");
        setMetaTag('meta[property="og:description"]', 'content', "Gallery of presentations made with AI");

        setMetaTag('meta[property="og:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);

        // Set the Twitter meta tags
        setMetaTag('meta[name="twitter:title"]', 'content', "Examples of Presentations Generated using Artificial Intelligence");
        setMetaTag('meta[name="twitter:description"]', 'content', "Gallery of presentations made with AI");
        setMetaTag('meta[name="twitter:image"]', 'content', `${process.env.PUBLIC_URL}/logo192.png`);
    }, []); // Dependencies array ensures this runs when `post` object changes

    return (
        <div>
            <div className="bg-white py-24 sm:py-32">
                <Header />
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-blue-600 sm:text-4xl">Presentations Made With PPTX.ai </h2>
                        <p className="mt-2 text-lg leading-8 text-blue-600">
                            Use AI to build and deliver amazing presentations
                        </p>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {blogs.map((post) => {
                            // Extract the iframe and the text after it from post.content
                            const { iframe, textAfterIframe } = extractIframeAndText(post.content);

                            return (
                                <a href={'/gallery/' + post.slug} key={post.page_id}>
                                    <article className="flex flex-col items-start justify-between">
                                        <div className="relative w-full">
                                            {/* If you want to include the iframe in the list of posts */}
                                            <div className="mt-16 max-w-2xl iframe-container" dangerouslySetInnerHTML={{ __html: iframe }}></div>
                                        </div>
                                        <div className="max-w-xl">
                                            <div className="mt-8 flex items-center gap-x-4 text-xs">
                                                <time dateTime={post.page_modified} className="text-gray-500">
                                                    {formatDate(post.page_modified)}
                                                </time>
                                            </div>
                                            <div className="group relative">
                                                <h3 className="mt-3 text-lg font-semibold leading-6 text-blue-600 group-hover:text-gray-600 text-center">
                                                    <span className="absolute inset-0" />
                                                    {post.title}
                                                </h3>

                                                {/* Displaying the extracted text after iframe */}
                                                <div className="mt-4 text-container">
                                                    <p className="mt-6" dangerouslySetInnerHTML={{ __html: extractFirstSentences(textAfterIframe, 3) }}></p>
                                                </div>

                                            </div>
                                        </div>
                                    </article>
                                </a>
                            );
                        })}


                    </div>
                </div>
            </div >
            <Footer />
        </div>


    )

}