import { useEffect } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { useTranslation } from './util/LanguageProvider'; // Import the useTranslation hook

const languages = [
    { name: 'English', initials: 'EN', bgColor: 'bg-pink-600' },
    { name: 'Español', initials: 'ES', bgColor: 'bg-blue-500' },
    { name: 'Français', initials: 'FR', bgColor: 'bg-purple-400' },
    { name: 'Deutsch', initials: 'DE', bgColor: 'bg-green-500' },
    { name: '中文', initials: 'ZH', bgColor: 'bg-red-600' },
    { name: '日本語', initials: 'JA', bgColor: 'bg-yellow-400' },
    { name: 'Русский', initials: 'RU', bgColor: 'bg-indigo-600' },
    { name: 'العربية', initials: 'AR', bgColor: 'bg-teal-500' },
    { name: 'Italiano', initials: 'IT', bgColor: 'bg-orange-400' },
    { name: 'Português', initials: 'PT', bgColor: 'bg-blue-600' },
    { name: 'Nederlands', initials: 'NL', bgColor: 'bg-pink-500' },
    { name: 'Svenska', initials: 'SV', bgColor: 'bg-green-600' },
    { name: '한국어', initials: 'KO', bgColor: 'bg-yellow-500' },
    { name: 'Türkçe', initials: 'TR', bgColor: 'bg-indigo-400' },
    { name: 'Ελληνικά', initials: 'EL', bgColor: 'bg-teal-600' },
    { name: 'Polski', initials: 'PL', bgColor: 'bg-purple-500' },
    { name: 'עברית', initials: 'HE', bgColor: 'bg-blue-700' },
    { name: 'Dansk', initials: 'DA', bgColor: 'bg-orange-500' },
    { name: 'Norsk', initials: 'NO', bgColor: 'bg-red-500' },
    { name: 'Suomi', initials: 'FI', bgColor: 'bg-pink-400' },
    { name: 'Čeština', initials: 'CS', bgColor: 'bg-green-700' },
    { name: 'Magyar', initials: 'HU', bgColor: 'bg-teal-400' },
    { name: 'Română', initials: 'RO', bgColor: 'bg-yellow-600' },
    { name: 'ไทย', initials: 'TH', bgColor: 'bg-purple-600' },
    { name: 'हिन्दी', initials: 'HI', bgColor: 'bg-orange-600' },
    { name: 'বাংলা', initials: 'BN', bgColor: 'bg-red-700' },
    { name: 'Tiếng Việt', initials: 'VI', bgColor: 'bg-pink-700' },
    { name: 'Українська', initials: 'UK', bgColor: 'bg-indigo-500' },
    { name: 'Bahasa Melayu', initials: 'MS', bgColor: 'bg-blue-800' },
    { name: 'Kiswahili', initials: 'SW', bgColor: 'bg-green-800' },
    { name: 'Filipino', initials: 'TL', bgColor: 'bg-teal-700' },
    { name: 'Català', initials: 'CA', bgColor: 'bg-yellow-700' },
    { name: 'ગુજરાતી', initials: 'GU', bgColor: 'bg-purple-700' },
    { name: 'Slovenčina', initials: 'SK', bgColor: 'bg-orange-700' },
    { name: 'தமிழ்', initials: 'TA', bgColor: 'bg-red-800' },
    { name: 'తెలుగు', initials: 'TE', bgColor: 'bg-pink-800' },
    { name: 'मराठी', initials: 'MR', bgColor: 'bg-blue-900' },
    { name: 'ਪੰਜਾਬੀ', initials: 'PA', bgColor: 'bg-green-900' },
    { name: 'ಕನ್ನಡ', initials: 'KN', bgColor: 'bg-teal-800' },
    { name: 'ଓଡ଼ିଆ', initials: 'OR', bgColor: 'bg-yellow-800' },
    { name: 'සිංහල', initials: 'SI', bgColor: 'bg-purple-800' },
    { name: 'Български', initials: 'BG', bgColor: 'bg-orange-800' },
    { name: 'Eesti', initials: 'ET', bgColor: 'bg-red-900' },
    { name: 'Latviešu', initials: 'LV', bgColor: 'bg-pink-900' },
    { name: 'Lietuvių', initials: 'LT', bgColor: 'bg-blue-800' },
    { name: 'Slovenščina', initials: 'SL', bgColor: 'bg-green-800' },
    { name: 'Қазақ', initials: 'KK', bgColor: 'bg-yellow-900' },
    { name: 'Oʻzbek', initials: 'UZ', bgColor: 'bg-purple-900' },
    { name: 'Azərbaycanca', initials: 'AZ', bgColor: 'bg-orange-900' },
    { name: 'Bosanski', initials: 'BS', bgColor: 'bg-red-400' },
    { name: 'Hrvatski', initials: 'HR', bgColor: 'bg-pink-800' },
    { name: 'Српски', initials: 'SR', bgColor: 'bg-blue-700' },
    { name: 'Македонски', initials: 'MK', bgColor: 'bg-green-900' },
    { name: 'Shqip', initials: 'SQ', bgColor: 'bg-teal-400' },
    { name: 'Հայերեն', initials: 'HY', bgColor: 'bg-yellow-300' },
    { name: 'Kurdî', initials: 'KU', bgColor: 'bg-purple-500' },
    { name: 'Тоҷикӣ', initials: 'TG', bgColor: 'bg-orange-300' },
    // Add more languages as needed
];

const messages = {
    en: {
        text: "Generate Presentations and Speaker's Notes in Many Languages",
    },
    es: {
        text: "Generar Presentaciones y Notas del Orador en Varios Idiomas",
    },
    fr: {
        text: "Générer des Présentations et des Notes de l'Orateur dans Plusieurs Langues",
    },
    de: {
        text: "Präsentationen und Sprechernotizen in vielen Sprachen generieren",
    },
    zh: {
        text: "生成多种语言的演示文稿和演讲者笔记",
    },
    ja: {
        text: "多言語でプレゼンテーションとスピーカーのノートを生成する",
    },
    ru: {
        text: "Создание презентаций и заметок для докладчика на разных языках",
    },
    ar: {
        text: "إنشاء عروض وملاحظات المتحدث بلغات متعددة",
    },
    it: {
        text: "Generare Presentazioni e Note per il Relatore in Molte Lingue",
    },
    pt: {
        text: "Gerar Apresentações e Notas do Apresentador em Vários Idiomas",
    },
    nl: {
        text: "Genereer Presentaties en Spreker's Notities in Veel Talen",
    },
    sv: {
        text: "Generera Presentationer och Talares Anteckningar på Många Språk",
    },
    ko: {
        text: "다양한 언어로 프레젠테이션 및 연설자 노트 생성",
    },
    tr: {
        text: "Çok Sayıda Dilde Sunumlar ve Konuşmacı Notları Oluşturun",
    },
    el: {
        text: "Δημιουργία Παρουσιάσεων και Σημειώσεων Ομιλητή σε Πολλές Γλώσσες",
    },
    pl: {
        text: "Generowanie Prezentacji i Notatek Prelegenta w Wielu Językach",
    },
    he: {
        text: "יצירת מצגות והסברי מרצה בשפות רבות",
    },
    da: {
        text: "Generer Præsentationer og Talenoter på Mange Sprog",
    },
    no: {
        text: "Generer Presentasjoner og Talerens Notater på Mange Språk",
    },
    fi: {
        text: "Luo Esityksiä ja Puhujan Muistiinpanoja Monilla Kielillä",
    },
    cs: {
        text: "Generovat Prezentace a Poznámky Řečníka v Mnoha Jazycích",
    },
    hu: {
        text: "Prezentációk és Előadói Jegyzetek Létrehozása Több Nyelven",
    },
    ro: {
        text: "Generați Prezentări și Note ale Vorbitorului în Mai Multe Limbi",
    },
    th: {
        text: "สร้างงานนำเสนอและบันทึกข้อคิดของผู้พูดในหลายภาษา",
    },
    hi: {
        text: "बहुमौली भाषाओं में प्रस्तुतिकरण और वक्ता के नोट उत्पन्न करें",
    },
    bn: {
        text: "বিভিন্ন ভাষায় প্রেজেন্টেশন এবং স্পিকারের নোট তৈরি করুন",
    },
    vi: {
        text: "Tạo Bài Trình Bày và Ghi Chú Người Diễn Thuyết Trong Nhiều Ngôn Ngữ",
    },
    uk: {
        text: "Генеруйте Презентації та Нотатки Доповідача на Багатьох Мовах",
    },
    ms: {
        text: "Cipta Persembahan dan Nota Penceramah dalam Pelbagai Bahasa",
    },
    sw: {
        text: "Jenga Uwasilishaji na Ujumbe wa Spika katika Lugha Nyingi",
    },
    tl: {
        text: "Lumikha ng mga Presentasyon at Mga Nota ng Tagapagsalita sa Maraming Wika",
    },
    ca: {
        text: "Genereu Presentacions i Notes d'Oradors en Moltes Llengües",
    },
    gu: {
        text: "ઘણી ભાષાઓમાં પ્રદર્શનો અને સ્પીકરની માટેની નોંધો બનાવો",
    },
    sk: {
        text: "Generujte Prezentácie a Poznámky Rečníka v Mnohých Jazykoch",
    },
    ta: {
        text: "பல மொழிகளில் பிரதிபர்ப்புக்கள் மற்றும் விரிவாசியின் குறிப்புகள் உருவாக்குக",
    },
    te: {
        text: "పలు భాషలలో ప్రజాదరణలను మరియు స్పీకర్ గమనాలను సృష్టించండి",
    },
    mr: {
        text: "विविध भाषांमध्ये प्रस्तुतिकरणे आणि वक्ता टिप्पण्या तयार करा",
    },
    pa: {
        text: "ਕਈ ਭਾਸ਼ਾਵਾਂ ਵਿੱਚ ਪ੍ਰਸ਼ਾਸਨ ਅਤੇ ਸਪੀਕਰ ਦੇ ਨੋਟਸ ਬਣਾਓ",
    },
    kn: {
        text: "ಹಲವು ಭಾಷೆಗಳಲ್ಲಿ ಪ್ರದರ್ಶನಗಳನ್ನು ಮತ್ತು ಸ್ಪೀಕರ್ ನೋಟ್ಸ್ ರಚಿಸಿ",
    },
    or: {
        text: "ଅନେକ ଭାଷାରେ ପ୍ରସ୍ତାବନା ଏବଂ ବକ୍ତାଙ୍କ ନୋଟସ୍ ତିଆରି କରନ୍ତୁ",
    },
    si: {
        text: "බහුලවාකේ වාක්‍ය ප්‍රදර්ශන උදාවනට උත්සාහ කරන්න",
    },
    bg: {
        text: "Създаване на презентации и бележки на говорителя на много езици",
    },
    et: {
        text: "Esitluste ja Sõnavõtjate Märkmete Genereerimine Mitmes Keeltes",
    },
    lv: {
        text: "Ģenerējiet Prezentācijas un Runātāja Piezīmes Dažādās Valodās",
    },
    lt: {
        text: "Generuokite Pristatymus ir Pranešėjo Pastabas Daugelyje Kalbų",
    },
    sl: {
        text: "Ustvarite Predstavitve in Opombe Govornika v Več Jezikih",
    },
    ka: {
        text: "შექმენით პრეზენტაციები და საკავშირო შენიშვნებები რამდენიმე ენისაგან",
    },
    kk: {
        text: "Көптілділерде Презентациялар және Орындаушы Пішіндерді жасау",
    },
    uz: {
        text: "Ko'plab Tillarda Prezentatsiyalarni va Nutqni Yaratish",
    },
    az: {
        text: "Çox Saylı Dillərdə Təqdimatlar və Nitq Qeydləri Yarat",
    },
    bs: {
        text: "Generišite Prezentacije i Bilješke Govornika na Više Jezika",
    },
    hr: {
        text: "Generirajte Prezentacije i Bilješke Govornika na Više Jezika",
    },
    sr: {
        text: "Генеришите Презентације и Белешке Говорника на Више Језика",
    },
    mk: {
        text: "Генерирајте Презентации и Белешки на Говорник на Повеќе Јазици",
    },
    sq: {
        text: "Generoni Prezantime dhe Shënimet e Folësit në Shumë Gjuhë",
    },
    hy: {
        text: "Գեներացեք Ներկայացուցիչներ և Կերպարների Նոթատեղեր Համալիր Լեզվերով",
    },
    ku: {
        text: "لە زمانی زۆری پرۆژه‌کانی تیسەر بکه‌وه‌",
    },
    tg: {
        text: "Дар Забонҳои Зиёд Презентасионҳо ва Шарҳҳои Суҳбаткунанда Исоб Фаромӯш Кунед",
    },
    // Add more translations as needed
};


function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Example() {

    const { userLanguage, translations } = useTranslation();

    const defaultLanguage = 'en'; // or whatever your default language is
    const { text } = messages[userLanguage] || messages[defaultLanguage];

    useEffect(() => {
        document.title = "Languages Supported for AI Generated PowerPoint and Google Slides";
        const descriptionMeta = document.querySelector('meta[name="description"]');
        if (descriptionMeta) {
            descriptionMeta.setAttribute('content', "Languages Supported for AI Generated PowerPoint and Google Slides"); // Set the page description
        }
    }, []);

    return (
        <div className="bg-white">
            <Header />
            <main className="mx-auto mt-16 max-w-7xl px-6 sm:mt-32 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <p className="mt-2 text-4xl font-bold tracking-tight text-blue-600 sm:text-5xl">
                        {text}
                    </p>
                </div>
                <div className="mt-16 flex justify-center">
                </div>
                <div className="mt-16 flex flex-wrap justify-center">
                    <div className="flex justify-center items-center">
                        <div className="isolate mx-auto grid max-w-screen-lg grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"> {/* Adjusted grid-cols */}
                            {languages.map((item) => (
                                <div key={item.name} className="col-span-1 flex rounded-md shadow-sm">
                                    <div
                                        className={classNames(
                                            item.bgColor,
                                            'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                                        )}
                                    >
                                        {item.initials}
                                    </div>
                                    <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                        <div className="flex-1 truncate px-4 py-2 text-sm">
                                            <a href={item.href} className="font-medium text-gray-900 hover:text-gray-600">
                                                {item.name}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}
