import { useState, useEffect } from 'react'
import makeApiRequest from './util/diffute';  // Importing makeApiRequest
import ApiError from './errors/pptx';
import { useNavigate } from 'react-router-dom';
import NotFound from './NotFound.js';
import Footer from './components/Footer.js';

import { useParams } from 'react-router-dom';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DeckFeedback() {

    const navigate = useNavigate();
    const { deck_id } = useParams();
    const [message, setMessage] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [hp, setHp] = useState('');
    const [http404error, setHttp404Error] = useState(null);
    const [http401error, setHttp401Error] = useState(null);
    const [http403error, setHttp403Error] = useState(null);
    const [generalError, setGeneralError] = useState(null);
    const [deck, setDeck] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const deckFeedback = async () => {
            try {
                const endpoint = `feedback/${deck_id}`;
                const response = await makeApiRequest(endpoint, navigate);

                if (response) {
                    setDeck(response.deck);
                    setSuccess(true);
                    setHttp404Error(null); // Clear any previous 404 error
                    setHttp401Error(null);
                    setHttp403Error(null);
                    setGeneralError(null);
                } else {
                    throw new ApiError('Something went wrong', 500);
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 404) {
                        setHttp404Error(error.message || 'Not Found'); // Set 404 error message
                    } else if (error.status === 401) {
                        setHttp401Error(error.message || 'Unauthorized');
                    } else if (error.status === 403) {
                        setHttp403Error(error.message || 'Forbidden');
                    } else {
                        setGeneralError(error.message || 'Request failed');
                    }
                } else {
                    setGeneralError('Request Error');
                }
                setSuccess(false);
            }
        };

        deckFeedback();
    }, [deck_id]);



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const csrfResponse = await makeApiRequest('csrf', null, 'GET');
            const csrfToken = csrfResponse.csrf_token;

            // Prepare headers with CSRF token
            const extraHeaders = {
                'x-csrf-token': csrfToken
            };

            const endpoint = `feedback/${deck_id}`;
            const method = 'POST';
            const data = {
                message,
                deck_id: deck_id,
                email_address: emailAddress,
                hp
            };

            const response = await makeApiRequest(endpoint, navigate, method, data, false, 4, {}, extraHeaders);

            if (response) {
                setSubmitSuccess(true);
            } else {
                throw new ApiError('Something went wrong', 500);
            }
        } catch (error) {
            if (error instanceof ApiError) {
                setGeneralError(error.message || 'Request failed');
            } else {
                setGeneralError('Request Error');
            }
        }
    };


    return (


        <div>


            {submitSuccess ? (


                <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div
                        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">Feedback on '{deck.deck.slide1.title}'</h2>
                        <p className="mt-2 text-lg leading-8 text-gray-600">
                            We got it.  Thank you.
                        </p>

                        <a href="/create">
                            <button
                                type="button"
                                className="mt-4 rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                            >
                                Create New Slides
                            </button>
                        </a>


                    </div>
                </div>
            ) :

                deck ? (
                    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
                        <div
                            className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                        </div>
                        <div className="mx-auto max-w-2xl text-center">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">Feedback on '{deck.deck.slide1.title}'</h2>
                            <p className="mt-2 text-lg leading-8 text-gray-600">
                                We could really use your feedback.  How did the deck turn out?  Did you love anything?  Did you hate anything?  Anything missing?  Be candid.
                            </p>
                        </div>
                        <form onSubmit={handleSubmit} method="POST" className="mx-auto max-w-xl sm:mt-20">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                <div className="sm:col-span-2">
                                    <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Message
                                    </label>
                                    <div className="mt-2.5">
                                        <textarea
                                            name="message"
                                            id="message"
                                            rows={4}
                                            value={message}
                                            onChange={e => setMessage(e.target.value)}
                                            required
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <label htmlFor="hp" className="block text-sm font-semibold leading-6 text-gray-900 invisible">
                                        hp
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            name="hp"
                                            id="hp"
                                            value={hp}
                                            onChange={(e) => setHp(e.target.value)}
                                            style={{ display: 'none' }}  // This makes it hidden
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="mt-10">
                                <button
                                    type="submit"
                                    className="block w-full rounded-md bg-blue-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                >
                                    Send feedback
                                </button>
                            </div>
                        </form>
                    </div>
                ) : http404error ? (
                    <div>
                        <NotFound />
                    </div>
                ) : http401error || http403error ? (
                    <div>
                        {http401error && <div>Unauthorized</div>}
                        {http403error && <div>Forbidden</div>}
                    </div>
                ) : generalError ? (
                    <div>{generalError}</div>
                ) : (
                    <div>Loading</div>
                )}

            <Footer />

        </div>

    )
}
