const steps = [
    { id: 'Step 1', name: 'Presentation Subject', href: '#' },
    { id: 'Step 2', name: 'Template', href: '#' },
    { id: 'Step 3', name: 'Confirm', href: '#' },
]

export default function Steps({ currentStep }) {
    const steps = [
        { id: 'Step 1', name: 'Drag and Drop PDFs, Word Docs or Enter a Subject', },
        { id: 'Step 2', name: 'Choose a Template', },
        { id: 'Step 3', name: 'Add Speakers Notes Style', },
        { id: 'Step 4', name: 'Confirm', },
    ];

    return (
        <nav aria-label="Progress">
            <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                {steps.map((step, index) => {
                    const stepStatus = index + 1 === currentStep ? 'current' :
                        index + 1 < currentStep ? 'complete' : 'upcoming';

                    return (
                        <li key={step.name} className="md:flex-1">
                            {stepStatus === 'complete' ? (
                                <a
                                    href={step.href}
                                    className="group flex flex-col border-l-4 border-blue-600 py-2 pl-4 hover:border-blue-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                >
                                    <span className="text-sm font-medium text-blue-600 group-hover:text-blue-800">{step.id}</span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </a>
                            ) : stepStatus === 'current' ? (
                                <a
                                    href={step.href}
                                    className="flex flex-col border-l-4 border-blue-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                    aria-current="step"
                                >
                                    <span className="text-sm font-medium text-blue-600">{step.id}</span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </a>
                            ) : (
                                <a
                                    href={step.href}
                                    className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                >
                                    <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </a>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
}

