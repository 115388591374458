import React, { createContext, useContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
    const [userLanguage, setUserLanguage] = useState(getPrimaryLanguage(navigator.language)); // Set the default language based on browser settings

    // Define translations for different languages
    // const translations = {

    //     en: {
    //         title: 'Hello!',
    //         message: 'Try our new ChatGPT interface for PPTX.ai!',
    //     },
    //     ar: {
    //         title: 'مرحبًا!',
    //         message: 'جرب توليد العروض التقديمية وملاحظات المتحدثين باللغة العربية!',
    //     },
    //     bn: {
    //         title: 'হ্যালো!',
    //         message: 'আপনি বাঙালি ভাষায় প্রেজেন্টেশন এবং স্পিকার নোট তৈরি করতে চেষ্টা করুন!',
    //     },
    //     de: {
    //         title: 'Hallo!',
    //         message: 'Versuchen Sie, Präsentationen und Sprechernotizen auf Deutsch zu generieren!',
    //     },
    //     es: {
    //         title: '¡Hola!',
    //         message: '¡Intente generar presentaciones y notas para oradores en español!',
    //     },
    //     fr: {
    //         title: 'Bonjour!',
    //         message: 'Essayez de générer des présentations et des notes pour les orateurs en français!',
    //     },
    //     hi: {
    //         title: 'नमस्ते!',
    //         message: 'अपने प्रस्तावनाओं और वक्ताओं के नोट हिंदी में उत्पन्न करने का प्रयास करें!',
    //     },
    //     id: {
    //         title: 'Halo!',
    //         message: 'Coba hasilkan presentasi dan catatan pembicara dalam bahasa Indonesia!',
    //     },
    //     it: {
    //         title: 'Ciao!',
    //         message: 'Prova a generare presentazioni e note per i relatori in italiano!',
    //     },
    //     ja: {
    //         title: 'こんにちは!',
    //         message: '日本語でプレゼンテーションとスピーカーノートを生成してみてください！',
    //     },
    //     ko: {
    //         title: '안녕하세요!',
    //         message: '한국어로 프레젠테이션 및 스피커 노트 생성을 시도해보세요!',
    //     },
    //     mr: {
    //         title: 'नमस्कार!',
    //         message: 'आपल्या प्रस्तावना आणि वक्ता नोट मराठी मध्ये तयार करण्याचा प्रयत्न करा!',
    //     },
    //     nl: {
    //         title: 'Hallo!',
    //         message: 'Probeer presentaties en sprekersnotities te genereren in het Nederlands!',
    //     },
    //     pl: {
    //         title: 'Cześć!',
    //         message: 'Spróbuj generować prezentacje i notatki dla prelegentów w języku polskim!',
    //     },
    //     pt: {
    //         title: 'Olá!',
    //         message: 'Tente gerar apresentações e notas para palestrantes em português!',
    //     },
    //     ro: {
    //         title: 'Salut!',
    //         message: 'Încercați să generați prezentări și note pentru vorbitori în limba română!',
    //     },
    //     ru: {
    //         title: 'Привет!',
    //         message: 'Попробуйте создать презентации и заметки для докладчиков на русском языке!',
    //     },
    //     zh: {
    //         title: '你好!',
    //         message: '尝试生成中文的演示文稿和演讲者笔记!',
    //     },
    // };

    const translations = {
        en: {
            title: 'Hello!',
            message: 'Try our new ChatGPT interface for PPTX.ai for ChatGPT Plus Users!',
        },
        ar: {
            title: 'مرحبًا!',
            message: 'جرب واجهة ChatGPT الجديدة لـ PPTX.ai لمستخدمي ChatGPT Plus!',
        },
        bn: {
            title: 'হ্যালো!',
            message: 'ChatGPT Plus ব্যবহারকারীদের জন্য PPTX.ai এর জন্য আমাদের নতুন ChatGPT ইন্টারফেস চেষ্টা করুন!',
        },
        de: {
            title: 'Hallo!',
            message: 'Probieren Sie unsere neue ChatGPT-Oberfläche für PPTX.ai für ChatGPT Plus-Benutzer aus!',
        },
        es: {
            title: '¡Hola!',
            message: '¡Prueba nuestra nueva interfaz de ChatGPT para PPTX.ai para usuarios de ChatGPT Plus!',
        },
        fr: {
            title: 'Bonjour!',
            message: 'Essayez notre nouvelle interface ChatGPT pour PPTX.ai pour les utilisateurs de ChatGPT Plus!',
        },
        hi: {
            title: 'नमस्ते!',
            message: 'ChatGPT Plus उपयोगकर्ताओं के लिए PPTX.ai के लिए हमारे नए ChatGPT इंटरफ़ेस का प्रयास करें!',
        },
        id: {
            title: 'Halo!',
            message: 'Cobalah antarmuka ChatGPT baru kami untuk PPTX.ai untuk Pengguna ChatGPT Plus!',
        },
        it: {
            title: 'Ciao!',
            message: 'Prova la nostra nuova interfaccia ChatGPT per PPTX.ai per gli utenti di ChatGPT Plus!',
        },
        ja: {
            title: 'こんにちは!',
            message: 'ChatGPT Plusユーザー向けのPPTX.ai用の新しいChatGPTインターフェースをお試しください！',
        },
        ko: {
            title: '안녕하세요!',
            message: 'ChatGPT Plus 사용자를 위한 PPTX.ai용 새로운 ChatGPT 인터페이스를 시도해 보세요!',
        },
        mr: {
            title: 'नमस्कार!',
            message: 'ChatGPT Plus वापरकर्त्यांसाठी PPTX.ai साठी आमच्या नवीन ChatGPT इंटरफेसचा प्रयत्न करा!',
        },
        nl: {
            title: 'Hallo!',
            message: 'Probeer onze nieuwe ChatGPT-interface voor PPTX.ai voor ChatGPT Plus-gebruikers!',
        },
        pl: {
            title: 'Cześć!',
            message: 'Wypróbuj nasz nowy interfejs ChatGPT dla PPTX.ai dla użytkowników ChatGPT Plus!',
        },
        pt: {
            title: 'Olá!',
            message: 'Experimente a nossa nova interface ChatGPT para PPTX.ai para usuários do ChatGPT Plus!',
        },
        ro: {
            title: 'Salut!',
            message: 'Încercați noua noastră interfață ChatGPT pentru PPTX.ai pentru utilizatorii ChatGPT Plus!',
        },
        ru: {
            title: 'Привет!',
            message: 'Попробуйте наш новый интерфейс ChatGPT для PPTX.ai для пользователей ChatGPT Plus!',
        },
        zh: {
            title: '你好!',
            message: '尝试我们的新ChatGPT界面适用于PPTX.ai的ChatGPT Plus用户！',
        },
    };

    useEffect(() => {
        // Update the userLanguage whenever it changes in the browser
        setUserLanguage(getPrimaryLanguage(navigator.language));
    }, []);

    function getPrimaryLanguage(language) {
        return language.split('-')[0];
    }

    return (
        <LanguageContext.Provider value={{ userLanguage, setUserLanguage, translations }}>
            {children}
        </LanguageContext.Provider>
    );
}

export function useTranslation() {
    return useContext(LanguageContext);
}
