export const getMediaFile = (media) => {
    // Define media query conditions for different image size categories
    const mediaQueries = {
        very_small: "(max-width: 319px)",
        small: "(min-width: 320px) and (max-width: 499px)",
        medium_small: "(min-width: 500px) and (max-width: 749px)",
        medium: "(min-width: 750px) and (max-width: 999px)",
        medium_large: "(min-width: 1000px) and (max-width: 1499px)",
        large: "(min-width: 1500px) and (max-width: 2499px)",
        very_large: "(min-width: 2500px)",
    };

    let preferredSize = null;

    // Determine the preferred size based on the media query
    for (const size in mediaQueries) {
        if (window.matchMedia(mediaQueries[size]).matches) {
            preferredSize = size;
            break;
        }
    }

    // If a preferred size is determined, attempt to find a media file that matches
    if (preferredSize) {
        // Flatten the array of arrays to make it easier to search through all media items
        const flatMedia = media.flat();

        const file = flatMedia.find(file => file.size === preferredSize);
        if (file) return file;
    }

    // Fallback logic: return the smallest available size or a specific default if no size matches
    // Flatten the media array for the fallback scenario as well
    const flatMedia = media.flat();

    return flatMedia.find(file => file.size) || null;
};