import React, { useState, useEffect } from 'react';

import templatesData from './TemplatesData';

export default function Templates({ selectedTemplateName, setSelectedTemplateName }) {

    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        // Set the templates data from the JSON file
        setTemplates(templatesData);
    }, []);

    const handleTemplateSelect = (id) => {
        setSelectedTemplateName(id);
    };

    // console.log("templates: " + JSON.stringify(templates));

    return (
        <div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    {templates.map((template) => (
                        <article key={template.template} className="flex flex-col items-start justify-between">
                            <div className="relative w-full" onClick={() => handleTemplateSelect(template.template)}>
                                <img

                                    src={template.thumbnail}
                                    alt=""
                                    className={`aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2] ${selectedTemplateName === template.template ? 'border-4 border-indigo-600' : ''
                                        }`}
                                />
                                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                            </div>
                            <div className="max-w-xl">
                                <div className="group relative">
                                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                        <a href={template.href}>
                                            <span className="absolute inset-0" />
                                            {template.template}
                                        </a>
                                    </h3>
                                    {/* You can replace this with the appropriate description field from your JSON data */}
                                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{template.description}</p>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
        </div>
    );
}