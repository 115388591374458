import { XMarkIcon } from '@heroicons/react/20/solid'
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from '../util/LanguageProvider'; // Import the useTranslation hook

export default function LanguageBanner() {

    const [isBannerVisible, setIsBannerVisible] = useState(true);
    const { userLanguage, translations } = useTranslation();
    const defaultLanguage = 'en'; // or whatever your default language is
    const translation = translations[userLanguage] || translations[defaultLanguage];
    const { title, message } = translation;

    const handleDismissClick = () => {
        setIsBannerVisible(false);
    };

    return (
        <>
            {isBannerVisible && (
                <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
                    <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-white px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5 border border-blue-300">
                        <p className="text-sm leading-6 text-blue-500">
                            <a href="https://chat.openai.com/g/g-CuVeDkN4t-pptx-ai">
                                <strong className="font-semibold">{title}</strong>
                                <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-[rgba(37,99,235,1)]" aria-hidden="true">
                                    <circle cx="1" cy="1" r="1" />
                                </svg>
                                {message}
                            </a>
                        </p>
                        <button type="button" className="-m-1.5 flex-none p-1.5" onClick={handleDismissClick}>
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon className="h-5 w-5 text-blue-500" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
